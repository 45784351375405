/* eslint-disable promise/param-names */
const get = (selector, multiple) => multiple ? document.querySelectorAll(selector) : document.querySelector(selector);

export const getElement = async(selector, multiple = false, showError = true, tryAgainMs = 200, retryTimes = 4) => {
  let tries = 0;
  while ((!get(selector, multiple) || get(selector, multiple)?.length === 0) && tries !== retryTimes) {
    tries++;
    await new Promise(r => setTimeout(r, tryAgainMs));
  }
  if (tries === retryTimes) {
    showError && console.log(`asyncElementGetter: element with the selector "${selector}" was not found in the DOM`);
  } else {
    return get(selector, multiple);
  }
};
